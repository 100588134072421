export interface UserModel {
  fullName: string;
}

export interface RoleSwitchDataModel {
  roles: string[];
  bpGuids: MsApp.Guid[];
  defaultTc: MsApp.Guid;
}

export const ACTIVE_USER_KEY = 'activeUser';
